export const Pages = {
  Home: '/',
  HomeAddresses: '/addresses',
  Profile: '/profile',
  Login: '/login',
  LoginWith401: '/login?reason=401',
  SignUpPhone: '/signup/phone',
  SignUpPersonalInfo: '/signup/personal-info',
  SignUpJobTypes: '/signup/job-types',
  SignUpPreferences: '/signup/preferences',
  SignUpTermsAndConditions: '/signup/terms-conditions',
  ProfilePersonalInfo: '/profile',
  ProfileJobTypes: '/profile/job-types',
  ProfilePreferences: '/profile/preferences',
  ProfileTermsAndConditions: '/profile/terms-conditions',
  VisitDetail: '/visit/:visitId',
  AddressDetail: '/address/:addressId',
};

export const getVisitDetailPageUrl = (visitId: string) =>
  Pages.VisitDetail.replace(':visitId', visitId);

export const getAddressDetailPageUrl = (addressId: string) =>
  Pages.AddressDetail.replace(':addressId', addressId);

export const AUTO_TUTORIAL_MODAL = 'auto-show-tutorial-modal';

export const CAIO_PHONE_NUMBER = '(857) 391-9807';
export const CAIO_PHONE_NUMBER_DIGITS = '8573919807';
export const CAIO_PHONE_NUMBER_TEL_LINK = `tel:${CAIO_PHONE_NUMBER_DIGITS}`;
