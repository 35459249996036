'use client'; // Error components must be Client Components

import { useRouter } from '@repo/routing';
import { logout } from '../logout.ts';
import { Pages } from '../constants.tsx';
import { useEffect } from 'react';
import { logError } from '@repo/client-logger/datadog/log';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();

  const handleLogout = async () => {
    await logout();
    router.push(Pages.Login);
  };

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    logError({ message: 'Error Page', error });
  }, []);

  return (
    <div>
      <h2>Something went wrong!</h2>
      <p>error: {error.message}</p>
      <button onClick={handleReset}>Reload</button>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}
